import React from 'react'
import { Collapse } from 'antd'

const { Panel } = Collapse

const SdkReleaseNotes = () => {

  return (
    <article>
      <h2 className="caption-primary">SDK Release Notes</h2>
      <Collapse 
        className="accordion"
        expandIconPosition="right"
      >     

    <Panel header="3.0.5" key ="11">
      <div className="data">June 03, 2022</div>          
        <section>
          <h5> General changes</h5>
          <li>
            UnityGLTF dependency is now required to be installed from Package Manager
          </li>
          <h5>Bug Fixes</h5>
          <ul>
            <li>
              GPS fix when entering the XR scene second time in the app lifecycle
            </li>              
          </ul>
        </section> 
      </Panel>
      
      <Panel header="3.0.0" key ="11">
        <div className="data">January 06, 2022</div>          
          <section>
            <h5> General changes</h5>
            <li>
              Redesign for faster initialization and reload
            </li>
            <li>
              XRCamera height is now calculated using ground plane detection
            </li>
            <li>
              Scan UI updated
            </li>
            <h5>Bug Fixes</h5>
            <ul>
              <li>
                Android 12 GPS fix
              </li>              
            </ul>
          </section> 
        </Panel>

        <Panel header="2.4.1 (Hotfix)" key ="10">
        <div className="data">September 14, 2021</div>          
          <section>
            <h5>Bug Fixes</h5>
            <ul>
              <li>
                Fixes session initialization timeout issue that gets stuck at 50 % waiting on GPS
              </li>              
            </ul>
          </section> 
        </Panel>
        
        <Panel header="2.4.0" key ="9">
        <div className="data">July 23, 2021</div>
          <section>
            <h5> General changes</h5>
            <ul>
              <li>
                SDK available through Git Url in Unity Package Manager
              </li>
              <li>
                Unity version updated to v2020.3.11f1
              </li>             
            </ul>
          </section>
          <section>
            <h5>Bug Fixes</h5>
            <ul>
              <li>
                Fixed Photosphere and Sample Editor script paths for provider sets
              </li>
              <li>
                Fixes Relocalization in Local mode (applicable only when using Photosphere/Sample provider set)
              </li>              
              <li>
                Fixed all example scenes that had breaking SturfeeXRSession object
              </li>
            </ul>
          </section> 
        </Panel>
        <Panel header="2.3.0" key="8">
          <div className="data">April 30, 2021</div>
          <section>
            <h5> General changes</h5>
            <ul>
              <li>
                SDK available through Unity Package Manager
              </li>
              <li>
                Added Bug/Issue Reporter that allows developers to send bug reports to Sturfee
              </li>              
            </ul>
          </section>
          <section>
            <h5>Bug Fixes</h5>
            <ul>
              <li>
                Text boxes width increased for UI elements in LocalizationScanner prefab
              </li>              
            </ul>
          </section>          
        </Panel>
        <Panel header="2.2.0" key="7">
          <div className="data">February 15, 2021</div>
          <section>
            <h5> General changes</h5>
            <ul>
              <li>
                Added improved UI 
              </li>
              <li>
                Configurable UI Color themes and Localized Strings
              </li>
              <li>
                Added sample Sturfee color theme for the UI 
              </li>
              <li>
                Added "Look Here" scene with UI to perform re-localization 
              </li>
              <li>
                Initial Look Up Circle raised by 5 degrees to encourage user to look up  before Scan
              </li>
              <li>
                Added "CameraDirection" option for XRCamera to allow use of Front Camera. This feature is limited only to "AR Provider Set"
              </li>
            </ul>
          </section>
          <section>
            <h5>Bug Fixes</h5>
            <ul>
              <li>
                Tile Cache is auto cleared when corrupted during tile loading. Avoids the need to reinstall app when tile corruption is encountered
              </li>
              <li>
                Re-localization scan (at "Look Here") message from VPS server is not displayed on the screen but instead logged as a warning in console
              </li>
            </ul>
          </section>          
        </Panel>
        <Panel header="2.1.1" key="6">
          <div className="data">January 18, 2021</div>
          <section>
            <h5> General changes</h5>
            <ul>
              <li>
                New data format for sample scenes to avoid pinging VPS in demo mode
              </li>
              <li>
                Option to add 360 photo spheres for immersive demo experience
              </li>
              <li>
                Multi-language support added. Use Sturfee/Configure window's Config tab. Currently supported languages : English, Japanese
              </li>
            </ul>
          </section>
          <section>
            <h5>Bug Fixes</h5>
            <ul>
              <li>
                Fixes all scan UI related bugs
              </li>
            </ul>
          </section>
          <section>
            <h5> Breaking Changes</h5>
            <ul>
              <li>
                Current Sample scene example updated to have new data format
              </li>
              <li>
                Sample names used for sample scene is no longer supported from this version of SDK. 
              </li>
            </ul>
          </section>
        </Panel>
        <Panel header="2.1.0" key="5">
          <div className="data">November 2, 2020</div>
          <section>
            <h5>General changes</h5>
            <ul>
              <li>
              Replaces GoogleARCore and UnityArKit plugins with ARFoundation 
              </li>
              <li>
              Scan restrictions added to start the scan at a slightly higher pitch. 
              UI updated correspondingly
              </li>
              <li>
              Config tab added to Sturfee Configuration Window. You can now configure the tile load size
              </li>
            </ul>
          </section>
          <section>
            <h5>Bug Fixes</h5>
            <ul>
              <li>              
              N/A
              </li>
            </ul>
          </section>
        </Panel>
        <Panel header="2.0.3" key="4">
          <div className="data">September 8, 2020</div>
          <section>
            <h5>General changes</h5>
            <ul>
              <li>
              Added option to Clear TileCache from Sturfee Menu
              </li>
              <li>
              Developer portal button added in Sturfee configuration window that links to Developer Portal
              </li>
            </ul>
          </section>
          <section>
            <h5>Bug Fixes</h5>
            <ul>
              <li>
              Fixes display grid projector bug where it does not show grid at high altitudes
              </li>
              <li>
              Fixes iOS build crash in Xcode during linking
              </li>
            </ul>
          </section>
        </Panel>
        <Panel header="2.0.2" key="3">
          <div className="data">August 30, 2020</div>
          <section>
            <h5>General changes</h5>
            <ui>
              <li>
                Added support for IL2CPP build
              </li>
              <li>
                Building shadows turned OFF
              </li>
              <li>
                Tile caching added
              </li>
              <li>
                Sample provider set defaults to a location in San Jose
              </li>
            </ui>
        </section>
          <section>
            <h5>Bug Fixes</h5>
            <ui>
              N/A
            </ui>
          </section>
        </Panel>
        <Panel header="2.0.1" key="2">
          <div className="data">July 19, 2020</div>
          <section>
            <h5>General changes</h5>
            <ul>
              <li>
                Exposes Localization request details in `OnFrameCaptured` event
              </li>
              <li>
                Sample Provider Set defaults to a location in San Francisco
              </li>
              <li>
                Building and terrain tiles on separate layers
              </li>  
              <li>
                Tile loading radius increased to 600m
              </li>
            </ul>
          </section>
          <section>
            <h5>Bug Fixes</h5>
            <ul>
              <li>
                Stop Scanning button fixed
              </li>
            </ul>
          </section>
        </Panel>
        <Panel header="2.0.0" key="1">
          <div className="data">December 2, 2019</div>
          <p>
            <ul>
              <li>
                First release
              </li>
              </ul> 
          </p>
            
        </Panel>
      </Collapse>
    </article>
  )
}

export default SdkReleaseNotes