import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'

import rootReducer from './rootReducer'

const loggerMiddleware = createLogger()

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(
      loggerMiddleware,
      thunkMiddleware
    )
  )
)

export default store