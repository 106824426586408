import types from './actionTypes'

const initialState = {
  tokenName: ''
}

const createTokenForm = ( state = initialState, action ) => {
  switch (action.type) {
    case types.TOKEN_NAME:
      return { 
        ...state, 
        tokenName: action.payload 
      }
    default:
      return state
  }
}

export default createTokenForm