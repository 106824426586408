import React from "react";

const Logout = () => {
    // Using Object destructuring

    // Here you can access all of keycloak methods and variables.
    // See https://www.keycloak.org/docs/latest/securing_apps/index.html#javascript-adapter-reference

    return (
        <div>
        </div>
    )
}

export default Logout