import React from 'react'
import { Link } from 'react-router-dom'

const Welcome = () => {

  return (
    <section className="welcome">
      <article>
        <h2 className="caption-primary">Welcome to the Sturfee Developer Portal</h2>
        <p>
            Welcome to the start of your world-scale AR journey! From this portal, you will  find everything you need to get started. If you are new here, we recommend that you begin with the Quick Start guide, which will help everything from downloading the SDK to making your first VPS AR app. 
        </p>
        <p>
            Since VPS is a location-based service, please see the Coverage Map for exactly where you will be able to test and use your VPS-powered apps. The SDK & Access Token page will allow you to generate tokens for each of your apps. The Documentation page will contain detailed functions for customizing your apps after you finish the Quick Start guide. 
        </p>
      </article>

      <article>
        <h2 className="caption-primary">Key Features</h2>
        <p> We are constantly improving the technology and here is an overview of how the Sturfee VPS SDK can currently supercharge your outdoor AR apps:
        </p>
        <p><b>Fast Localization: 
</b> Quickly determine the user’s position and orientation before loading the AR experience.</p>
      <p><b>3D Geometry Detection: 
</b> Automatically load and align 3D ground and building models around the user for interaction.</p>
      <p><b>Persistent World Anchors: 
</b> Digital objects placed in VPS remain in the real world even after the app is off just like real objects. </p>
      <p><b>Multi-user Ready: 
</b> Multiple users can simultaneously see the same experience from different perspectives.</p>
      </article>

      
      <article>
        <h2 className="caption-primary">About the SDK</h2>
        <section>
          <h3 className="caption-secondary">Localization and Geometry Detection</h3>
          <p>
            Sturfee has developed the world's first city-scale Visual Positioning 
            Service (VPS) using cutting edge advancements in satellite imaging, 
            deep learning, geometrical computer vision, and big data to provide on
            the-ground localization and environmental awareness to cameras based on
            what's seen from the sky. Using just a few pictures from your camera 
            device, we are able to determine:
          </p>
          <pre>
            <code>
              <ul>
                <li>accurate user location</li>
                <li>user's orientation and gaze target</li>
                <li>3D geometry of the local surroundings (planar surfaces and meshes)</li>
              </ul>
            </code>
          </pre>
        </section>
        <section>
          <h3 className="caption-secondary">Localization Flow</h3>
          <p>
            VPS localization follows roughly the same flow for each using the 
            Sturfee-VPS SDK.
          </p>
          <ul>
            <li>
              Initialize the XR Session using the 
              <a href="https://github.com/sturfeeinc/Sturfee.Unity.VPS.Documentation/wiki/XRSessionManager">XRSessionManager</a> (Turns on the
              device camera and sensors)
            </li>
            <li>
              Point the camera at the surroundings (take image(s))
            </li>
            <li>
              Ask the SDK to localize (send image(s) to the server)
            </li>
            <li>
              Wait for the localization to complete (server response; usually
              within 1-2 seconds)
            </li>
            <li>
              Initiate the AR experience (use your imagination!)
            </li>
          </ul>
        </section>
        
        
      </article>
    </section>
  )
}

export default Welcome