import React from 'react'

const Analytics = () => {

  return (
    <section>
      <h2 className="caption-primary">Analytics</h2>
      <p>
        Coming Soon!
      </p>
    </section>
  )
}

export default Analytics