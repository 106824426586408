import React,{ useRef, useEffect } from 'react'
import { Link } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';
import './CoverageMap.css';
mapboxgl.accessToken = 'pk.eyJ1IjoiYW1jMTg3IiwiYSI6ImNqZDZlZWt0eDFob3Ayd2w5cTlhbHR4MnYifQ.BtKGBCmuv04Twkr3ZYXlng';


const CoverageMap = () => {
    const mapContainerRef = useRef(null);
    // initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      // See style options here: https://docs.mapbox.com/api/maps/#styles
      style: 'mapbox://styles/amc187/cjd0llvpw1n7m2rnuoo0dveo5',
      center: [139.768916, 35.678499],
      zoom: 7.5,
    });

    // add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    

    // clean up on unmount
    return () => map.remove();
  }, []);
    
  return (
    <section>
      <h2 className="caption-primary">Coverage Map</h2>
      <p>
        Below is our current VPS coverage. The orange areas are active and ready for usage. The blue areas are not enabled yet and can be requested. Please submit your request <a href='https://forms.gle/RfPB5hNPF4eYZNBY9' className="link-primary">
    here</a>.
      </p>
      
      
      
      <div className="map-container" ref={mapContainerRef} />
      
    </section>
  )
}

export default CoverageMap