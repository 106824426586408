import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { setEditingTokenKey, toDelete, toEdit } from '../'
import { getEditingTokenKey } from '../../../selectors'

import { Popover, Button } from 'antd'
import { EllipsisOutlined  } from '@ant-design/icons'

const ActionIcon = ({ record, setFields }) => {
  const tokenKey = record.key
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const editingTokenKey = useSelector(getEditingTokenKey)
  
  const handleVisibleChange = visible => {
    setVisible(visible)

    !visible && dispatch(setEditingTokenKey(''))
  }

  const handleEdit = () => {
    // dispatch(toEdit(true))
    // setFields(record)
    dispatch(setEditingTokenKey(''))

    history.push({
      pathname: '/sdk/edit-token',
      state: {
        token: record
      }
    })
  }

  const handleDelete = () => {
    dispatch(toDelete(true))
  }

  const handleClick = () => {
    dispatch(setEditingTokenKey(tokenKey))
  }

  if (editingTokenKey === '' || editingTokenKey === tokenKey) {
    return (
      <Popover
        content={
          <div>
            <div>
              <Button 
                onClick={ handleEdit }
                type="link"
                size="large" 
              >
                Edit
              </Button>
            </div>
            <div>
              <Button
                onClick={ handleDelete } 
                type="link" 
                size="large" 
                danger
              >
                Delete
              </Button>
            </div>
          </div>
        }
        visible={ visible }
        onVisibleChange={ handleVisibleChange }
        trigger="click"
        placement="topLeft"
      >
        <Button
          onClick={ handleClick }
          size="large" 
          type="link" 
          icon={ <EllipsisOutlined /> }
          className="action-icon" 
        />
      </Popover>
    )
  } else {
    return (
      <Button
        size="large" 
        type="link" 
        icon={ <EllipsisOutlined /> } 
        className="action-icon" 
      />
    )
  }

  
}

export default ActionIcon