import React from 'react'
import {Link} from 'react-router-dom'

import objectsTab from '../../../../assets/images/steps/ar-setup/objects-tab.png'
import worldAnchor from '../../../../assets/images/steps/ar-setup/world-anchor.png'
import mapGps from '../../../../assets/images/steps/ar-setup/map-gps.png'

const ArSetup = () => (
  <ul>
    <li>
      <p>
        Delete the default "Main Camera" from the scene.
      </p>
      
    </li>
    <li>
      <p>
        In the Sturfee Editor window, click on the "Objects" tab.
      </p>
      <p>
        <img 
          src={ objectsTab } 
          className="responsive-image"
          alt="bjects tab" 
        />
      </p>
    </li>
    <li>
      <p>
        If this is your first time, you should see "First time Setup". Go 
        ahead and click that before proceeding further.
      </p>
    </li>
    <li>
      <p>
        Click the "Create XRSession" button and note that a new GameObject 
        was added to the scene.
      </p>
    </li>
    <li>
      <p>
        Click the "Create XRCamera" button and note that a new Camera was 
        added to the scene.
      </p>
    </li>
    <li>
      <p>
        Click the "Create XRLight" button and note that a new light was added to the scene. 
        Remove the default "Directional Light" from the scene since XRLight is also a directional light.
      </p>
    </li>
    <li>
      <p>
        Click the "Create WorldAnchor" button and note that a new GameObject 
        was added to the scene.
      </p>
    </li>
    <li>
      <p>
        In the scene, add a 3D object (i.e. Cube, Sphere, etc) as a child 
        to the new WorldAnchor GameObject.
      </p>
      <p>
        <img 
          src={ worldAnchor } 
          className="responsive-image"
          alt="world anchor" 
        />
      </p>
    </li>
    <li>
      <p>
        Click on the WorldAnchor in the hierarchy and find the attached 
        WorldAnchor script in the inspector. Set the GPS coordinates 
        somewhere nearby (you can use an online map to get a valid location 
        and elevation, such as <a href="https://www.mapcoordinates.net/en">this</a>).
      </p>
      <p>
        <img 
          src={ mapGps } 
          className="responsive-image"
          alt="map gps" 
        />
      </p>
    </li>
    <li>
      <p>
        Add SatelliteScanController.prefab from Assets/SturfeeVPS/Scanner/Templates/ to your scene. 
        This will take care of all aspects of localization, including the UI, calls to the 
        sturfee VPS server, and event handling.
      </p>
    </li>
    <li>
      If EventSystem is not added by default add EventSystem by selecting UI/EventSystem
      after right-clicking in hierarchy window
    </li>
  </ul> 
)

export default ArSetup