import React from 'react'

import SdkSetup from './steps/SdkSetup'
import ArSetup from './steps/ArSetup'
import ArSelection from './steps/ArSelection'
import BuildApp from './steps/BuildApp'
import TestApp from './steps/TestApp'

import { Collapse } from 'antd'

const { Panel } = Collapse

const Steps = () => {

  return (
    <article className="steps">
      <h2 className="caption-primary">Build Your First App in 5 Steps</h2>
      <Collapse 
        className="accordion"
        expandIconPosition="right"
      >
        <Panel header="1. Setup SDK">
          <SdkSetup />
        </Panel>

        <Panel header="2. AR Scene Setup">
          <ArSetup />
        </Panel>

        <Panel header="3. ARKit & ARCore Selection">
          <ArSelection />
        </Panel>

        <Panel header="4. Build App">
          <BuildApp />
        </Panel>

        <Panel header="5. Test App Outside">
          <TestApp />
        </Panel>
      </Collapse>
    </article>
  )
}

export default Steps