import React from 'react'

import arCorePluginProvider from '../../../../assets/images/steps/ar-selection/arcore-plugin-provider.png'
import arKitPluginProvider from '../../../../assets/images/steps/ar-selection/arkit-plugin-provider.png'
import arKProviderSet from '../../../../assets/images/steps/ar-selection/ar-provider-set.png'


const ArSelection = () => (
  <div>
    <section style={{ marginBottom: '20px' }}>
      <h3 className="caption-secondary">Setting up ArKit</h3> 
      <p>
        <ul>
          <li>
            Go to <code className="code-text">Edit->Project Settings->XR Plugin Management</code> and 
            select <code className="code-text">ARKit</code> as Plug-in providers
          </li>
          <p>
            <img 
              src={ arKitPluginProvider }
              className="responsive-image" 
              alt="ar kit" 
            />
          </p>
          <li>
            In the Provider Set dropdown of <code className="code-text">
              SturfeeXRSession</code> gameobject in scene select AR Provider Set.
          </li>
          <p>
            <img 
              src={ arKProviderSet }
              className="responsive-image" 
              alt="ar kit" 
            />
          </p>
        </ul>
         
      </p>
      
    </section>
    <section style={{ marginBottom: '20px' }}>
      <h3 className="caption-secondary">Setting up ArCore</h3>
      <p>
      <ul>
          <li>
            Go to <code className="code-text">Edit->Project Settings->XR Plugin Management</code> and
             select <code className="code-text">ARCore</code> as Plug-in providers
          </li>
          <p>
            <img 
              src={ arCorePluginProvider }
              className="responsive-image" 
              alt="ar kit" 
            />
          </p>
          <li>
            In the Provider Set dropdown of <code className="code-text">
              SturfeeXRSession</code> gameobject in scene select AR Provider Set.
          </li>
          <p>
            <img 
              src={ arKProviderSet }
              className="responsive-image" 
              alt="ar kit" 
            />
          </p>
        </ul>
          
      </p>
      
    </section>
  </div>
)

export default ArSelection