import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

const SampleApps = () => {
  const handleClick = () => {

  }

  return (
    <section className="sample-apps">
      <h2 className="caption-primary">Sample Apps</h2>
      <p>
      Coming Soon!
      </p>
      
      
    </section>
  )
}

export default SampleApps

{/* <p>
        Below are a few sample apps you can download to test out VPS in our
        coverage areas. Please make sure you are using 
        a <Link className="link-primary" to="/compatible-devices">compatible device</Link> before installing.
      </p>
      <h3 className="caption-secondary">StreetAR</h3>
      <p>
        An app that let's the user localize and tap on structures to reveal a 3D
        grid. See this <Link className="link-primary" to="">video</Link> for instructions.
      </p>
      <p>
        Android Download 
        <Button
          onClick={ handleClick } 
          type="primary" 
          shape="round" 
          icon={ <DownloadOutlined /> } 
          className="button-primary"
          style={{ marginLeft: '15px' }}
        >
            <a href="https://www.w3schools.com"> Download StreetAR APK</a>
        </Button>
      </p>
      <p>
        iOS Download (coming soon)
      </p>
      <p>
        Get hands on and build your own StreetAR app. Make sure you have 
        the <Link className="link-primary" to="">latest SDK</Link> installed.
      </p>
      <p>
        <Button
          type="primary" 
          shape="round" 
          className="button-primary"
        >
            <a href="https://www.w3schools.com"> Go to Github</a>
        </Button>
      </p> */}