import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { updateToken } from '../../sdk'

import { TokenRaw } from '../../sdk'
import { Button, Form, Input } from 'antd'

const EditableToken = ({ token }) => {
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  const history = useHistory()
  const location = useLocation()
  
  // useEffect(() => {
  //   form.setFieldsValue({
  //     name: token.name
  //   })
  // }, [isEdit])

  const handleClick = () => {
    // setIsEdit(true)
    history.push({
      pathname: '/quick-start/edit-token',
      state: {
        from: location.pathname,
        token
      }
    })
  }

  // const onFinish = (values) => {
  //   setIsLoading(true)

  //   dispatch(updateToken(token.id, values))
  //     .finally(() => {
  //       setIsLoading(false)
  //       setIsEdit(false)
  //     })
  // }

  // const handleCancel = () => {
  //   setIsEdit(false)
  // }

  return (
    <div>
      {/* { 
        isEdit ? (
          <Form
            form={ form }
            onFinish={ onFinish } 
            style={{
              display: 'flex'
            }}
          >
            <Form.Item shouldUpdate={true}>
              {
                () => (
                  <Button
                    disabled={ form.getFieldsError().filter(({ errors }) => errors.length).length }
                    loading={ isLoading }
                    htmlType="submit"
                    type="primary" 
                    shape="round" 
                    className="button-primary"
                    style={{
                      marginRight: "10px"
                    }}
                  >
                    Save
                  </Button>
                )
              }
            </Form.Item>
            <Form.Item
              style={{
                marginRight: "25px"
              }}
            >
              <Button
                onClick={ handleCancel }
                type="primary" 
                shape="round" 
                className="button-secondary"
              >
                Cancel
              </Button>
            </Form.Item>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                { required: true, message: 'Please input token name' }
              ]}
            >
               <Input />
            </Form.Item>
          </Form> ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '24px'
            }}
          >
            <Button
              onClick={ handleClick }
              style={{
                marginRight: "25px"
              }}
              type="primary" 
              shape="round" 
              className="button-primary"
            >
              Edit Token
            </Button>
            <div>
              Name: { token.name }
            </div>
          </div> )
      } */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '24px'
        }}
      >
        <Button
          onClick={ handleClick }
          style={{
            marginRight: "25px"
          }}
          type="primary" 
          shape="round" 
          className="button-primary"
        >
          Edit Token
        </Button>
        <div>
          Name: { token.name }
        </div>
      </div>
      <TokenRaw tokenRaw={ token.tokenRaw } />
    </div>
  )
}

export default EditableToken