import React from 'react'

const Documentation = () => {

  return (
    <section>
      <h2 className="caption-primary">Documentation</h2>
      <p> 
        All SDK related documentation can be found 
        <a href="https://github.com/sturfeeinc/Sturfee.Unity.VPS.Documentation/wiki"> here</a> 
      </p>
    </section>    
  )
}

export default Documentation