import { createActions } from 'redux-actions'
import types from './actionTypes'
import api from '../../api'

/* Action creators */ 
export const {
  setTokens,
  setEditingTokenKey,
  toDelete,
  toEdit,
  tokensIsLoading,
  coverageCheckLoading,
  setCoverageCheck,
  setCheckedCoverage
} = createActions(types.SET_TOKENS, 
  types.SET_EDITING_TOKEN_KEY,
  types.TO_DELETE,
  types.TO_EDIT,
  types.TOKENS_IS_LOADING,
  types.COVERAGE_CHECK_LOADING,
  types.SET_COVERAGE_CHECK,
  types.SET_CHECKED_COVERAGE,
)

/* Thunk */
export const getTokens = () => dispatch => {
  dispatch(tokensIsLoading(true))

  api.getTokens()
    .then(res => {

      console.log("===========================> 3")
      const tokens = res.data

      dispatch(tokensIsLoading(false))
      dispatch(setTokens(tokens))
    })
}

export const deleteToken = tokenId => dispatch => {

  const config = {
    params: {
      tokenId
    }
  }

  return api.revokeToken(null, config)
    .then(() => {
      dispatch(getTokens())
    })
}

export const updateToken = (tokenId, newData) => dispatch => {
  const data = {
    ...newData,
    tokenId,
    description: 'sadasd'
  }

  return api.updateToken(data)
    .then(() => {
      dispatch(getTokens())
    })
}

export const getCoverage = (token, latitude, longitude) => dispatch => {

  dispatch(coverageCheckLoading(true));

  const data = {
    lat: latitude,
    lng: longitude,
    token: token    
  }

  return api.getAlignmentAvailable(data)
    .then(response => {
      console.log(`Coverage Available Response: ${response}`);
      dispatch(coverageCheckLoading(false));
      dispatch(setCoverageCheck(true));
    })
    .catch(err => {
      console.log(`ERROR: Coverage Available error => ${err.message}`);
      dispatch(coverageCheckLoading(false));
      dispatch(setCoverageCheck(false));
    });
}