/* Client */
export const login = '/login'
export const user = '/user'
export const passwordRestoration = '/password-restoration'
export const emailConfirm = '/email-confirm'
export const resendEmailConfirm = '/resend-email-confirm'
export const reenterEmail = '/reenter-email'

/* Tokens */ 
export const tokens = '/account'
export const activate = '/activate'
export const register = '/register/'
export const revoke = '/revoke'
export const update = '/update'
export const clean = '/clean'
export const alignmentAvailable = '/alignment_available/'