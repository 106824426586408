import React from 'react'
import logo from '../../../assets/images/logo.png'
import { Link } from 'react-router-dom'

const Header = () => (
  <header className='header'>
    <div className="logo">
      <Link to="">
        <img 
          src={ logo }
          className="logo" 
          alt="logo" 
          width="200"
          height="40"
        />
      </Link>
    </div>
  </header>
)

export default Header