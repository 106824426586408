import types from './actionTypes'

const initialState = false

const isMobileMenuOpen = ( state = initialState, action ) => {
  switch (action.type) {
    case types.MENU_IS_OPEN:
      return action.payload
    default:
      return state
  }
}

export default isMobileMenuOpen