import React from 'react'

import packageARF from '../../../../assets/images/steps/sdk-setup/package-ARF.png'
import menuConfigure from '../../../../assets/images/steps/sdk-setup/menu-configure.png'
import pasteToken from '../../../../assets/images/steps/sdk-setup/paste-token.png'
import editorWindow from '../../../../assets/images/steps/sdk-setup/editor-window.png'
import packageInstall from '../../../../assets/images/steps/sdk-setup/package-install.png'
import installedSdkUpm from '../../../../assets/images/steps/sdk-setup/installed-sdk.png'
import addPackageFromGit from '../../../../assets/images/steps/sdk-setup/add-package-from-git.png'
import dllConflictError from '../../../../assets/images/steps/sdk-setup/dll-conflict-error.png'

const SdkSetup = () => (
  <ul>   

    <li>
      <p>
        In Unity, open <code className="code-text">Window/Package Manager</code> and on top left select '+' button and 
          chose "Add package from Git URL.." Enter this url "https://github.com/sturfeeinc/com.sturfee.vps.sdk.git"
      </p>
      <p>
        <img 
          src={ addPackageFromGit } 
          className="responsive-image" 
          alt="add from disk" 
        />
      </p>
    </li>    
    <li>
      <p>
        The Packages folder in Project Window should now show "Sturfee VPS SDK" folder.        
        Double-click "Sturfee-VPS-SDK" to install SDK
      </p>
      <p>
        <img 
          src={ installedSdkUpm } 
          className="responsive-image" 
          alt="installed SDK package" 
        />
      </p>      
    </li>           
    <li>
      <p>
         Go to Sturfee->Configure in the 
        main menu.
      </p>
      <p>
        <img 
          src={ menuConfigure } 
          className="responsive-image" 
          alt="menu configure" 
        />
      </p>
    </li>
    <li>
      <p>
        Paste your access token and hit 
        "Request Access".
      </p>
      <p>
        <img 
          src={ pasteToken } 
          className="responsive-image"
          alt="paste token" 
        />
      </p>
    </li>
    <li>
      <p>
        This should unlock more options in the Sturfee Editor window. 
        Keep this window open for the next section.
      </p>
      <p>
        <img 
          src={ editorWindow } 
          className="responsive-image"
          alt="editor window" 
        />
      </p>
    </li>
  </ul>
)

export default SdkSetup