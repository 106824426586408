import React from 'react'
import { Link } from 'react-router-dom'

const NextStep = () => {

  return (
    <article>
      <h2 className="caption-primary">What's Next</h2>
      <p>
        Congratulations on building your first world-scale AR app! Be sure to check
        out API reference <a href="https://github.com/sturfeeinc/Sturfee.Unity.VPS.Documentation/wiki/XRSession">page</a> before blazing your own path. You can also browse
        through the full SDK documentation <Link className="link-primary" to="/documentation">here</Link>.
      </p>
      <p>
        Wondering how to add your content in scene ? Check out <a href="https://github.com/sturfeeinc/Sturfee.Unity.VPS.Documentation/wiki/Using-WorldAnchor"> Using WorldAnchors</a>
      </p>
      <p>
        Furthermore, you check out the example scenes that comes along with this SDK <a href="https://github.com/sturfeeinc/Sturfee.Unity.VPS.Documentation/wiki/Examples"> here</a>
      </p>
    </article>
  )
}

export default NextStep