
export const getTokenName = state => state.createTokenForm.tokenName
export const getIsMobileMenuOpen = state => state.isMobileMenuOpen
export const getIsAuth = state => state.home.isAuthenticated
export const getMainPageIsLoading = state => state.home.isMainPageLoading

/* Email for password restoration */
export const getEmailForResetPassword = state => state.passwordResetForm.email

/* Email fot verification */
export const getEmailVarification = state => state.verificationEmail

/* User information */
export const getUserFullname = state => state.user.fullname
export const getUsername = state => state.user.username
export const getUserEmail = state => state.user.email
export const getUserOrganization = state => state.user.organization
export const getUserUuid = state => state.user.uuid

/* Tokens */
export const getTokens = state => state.tokens.items
export const getEditingTokenKey = state => state.tokens.editingTokenKey
export const getToDelete = state => state.tokens.toDelete
export const getToEdit = state => state.tokens.toEdit
export const getTokensIsLoading = state => state.tokens.isLoading

/* Coverage */
export const getCoverageCheckIsLoading = state => state.tokens.coverageIsLoading;
export const getCoverageCheck = state => state.tokens.coverageResult;
export const getHasCheckedCoverage = state => state.tokens.hasCheckedCoverage;