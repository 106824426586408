import { combineReducers } from 'redux'

import { reducer as createTokenReducer} from './pages/create-token'
import { reducer as  mobileMenuButtonReducer } from './components/mobile-menu-button'
// import { reducer as loginReducer } from './pages/login'
import { reducer as profileReducer } from './pages/profile'
// import { reducer as passwordResetReducer } from './pages/password-reset'
// import { reducer as signUpReducer } from './pages/sign-up'
import { reducer as sdkReducer } from './pages/sdk'
// import { reducer as verificationEmailReducer } from './pages/check-email-notification'
import { reducer as homeReducer } from './pages/home'

export default combineReducers({
  createTokenForm: createTokenReducer,
  isMobileMenuOpen: mobileMenuButtonReducer,
  // isAuthenticated: loginReducer,
  // passwordResetForm: passwordResetReducer,
  user: profileReducer,
  // signUpForm: signUpReducer,
  tokens: sdkReducer,
  // verificationEmail: verificationEmailReducer,
  home: homeReducer,
})