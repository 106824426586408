import types from './actionTypes'

const token = localStorage.getItem('token')

const initialState = {
  isMainPageLoading: false,
  isAuthenticated: token ? true : false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.MAIN_PAGE_IS_LOADING:
      return {
        ...state,
        isMainPageLoading: action.payload
      }
    case types.IS_AUTH:
      return {
        ...state,
        isAuthenticated: action.payload
      }
    default:
      return state
  }
}

export default reducer