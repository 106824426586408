import { createActions } from 'redux-actions'
import types from './actionTypes'

const {
  USER_FULLNAME,
  USER_NAME,
  USER_EMAIL,
  USER_ORGANIZATION,
  USER_UUID
} = types

export const {
  userFullname,
  userName,
  userEmail,
  userOrganization,
  userUuid
} = createActions(USER_FULLNAME,
  USER_NAME, 
  USER_EMAIL, 
  USER_ORGANIZATION, 
  USER_UUID
)