import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { 
  updateToken, 
  setEditingTokenKey,
  toEdit
} from '../'
import { 
  getTokens, 
  getEditingTokenKey, 
  getToDelete,
  getToEdit,
  getTokensIsLoading
} from '../../../selectors'

import ActionIcon from './ActionIcon'
import EditActions from './EditActions'
import DeleteActions from './DeleteActions'
import EditableCell from './EditableCell'
import TokenRaw from './TokenRaw'
import { Table, Form } from 'antd'


const TokenTable = () => {
  console.log("===========================> 1")
  const dispatch = useDispatch()
  const tokens = useSelector(getTokens)
  const editingTokenKey = useSelector(getEditingTokenKey)
  const isEdit = useSelector(getToEdit)
  const isDelete = useSelector(getToDelete)
  const isLoding = useSelector(getTokensIsLoading)

  console.log("===========================> 11")
  console.log(tokens)
  console.log("===========================> 12")
  const [form] = Form.useForm()

  // Since rowKey prop for Table doesn't work we add key for every token equivalent token id
  const copyTokens = JSON.parse(JSON.stringify(tokens))

  copyTokens.forEach(token => {
    token.key = token.id
  })

  const isEditing = record => record.key === editingTokenKey

  const setFields = record => {
    form.setFieldsValue({ ...record })
  }

  const handleSave = async (tokenId) => {
    const newData = await form.validateFields()
    
    dispatch(updateToken(tokenId, newData))
      .then(() => {
        dispatch(setEditingTokenKey(''))
        dispatch(toEdit(false))
      })
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      className: 'name-cell',
      align: 'left',
      width: '20%',
      editable: true
    },
    {
      title: 'Token',
      dataIndex: 'tokenRaw',
      className: 'token-cell',
      align: 'center',
      render: tokenRaw => <TokenRaw tokenRaw={ tokenRaw } />
    },
    {
      title: '',
      dataIndex: 'actions',
      className: 'action-cell',
      align: 'center',
      width: '60px',
      render: (_, record) => {
        const editable = isEditing(record)

        if (editable && isDelete) {
          return <DeleteActions tokenId={ record.id } />
        } else if (editable && isEdit) {
          return <EditActions handleSave={ handleSave } tokenId={record.id} />
        } else {
          return <ActionIcon record={ record } setFields={ setFields } />
        }     
      }
    }
  ]

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  return (
    <Form
      form={ form }
      component={ false }
    >
      <Table 
        columns={ mergedColumns } 
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={ copyTokens } 
        pagination={ false }
        loading={ isLoding }
      />
    </Form>
  )
}

export default TokenTable

