import React from 'react'

import Start from './Start'
import BeforeStart from './BeforeStart'
import Steps from './Steps'
import NextStep from './NextStep'

const QuickStart = () => {

  return (
    <section className="quick-start">
      <Start />
      <BeforeStart />
      <Steps />
      <NextStep />
    </section>
  )
}

export default QuickStart