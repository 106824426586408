import { createActions } from 'redux-actions'
import types from './actionTypes'
import api from '../../api'

const {
  TOKEN_NAME
} = types

export const { 
  tokenName
} = createActions(TOKEN_NAME)

/* Thunk */
export const createToken = () => (_, getState) => {
  const {
    tokenName: name
  } = getState().createTokenForm


  const data = {
    description: 'demo',
    name
  }

  return api.createToken(data)
}



