import React, { useEffect } from 'react'
import { 
  Route, 
  Switch, 
  Redirect, 
  useLocation,
  useHistory 
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import routes from '../../../routes'
import { getIsAuth, getMainPageIsLoading } from '../../../selectors'

// Actions
import { getUser, mainPageIsLoading, isAuth } from '../actions'
import { getTokens } from '../../sdk'

// Components
import { Sidebar } from '../../../components/sidebar'
import { MobileMenuButton } from '../../../components/mobile-menu-button'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { useKeycloak } from '@react-keycloak/web'
import {apiClient, apiToken} from "../../../api/axios-instance";
import {saveToLocalStorage} from "../../../helpers";


const Home = () => {
  const isLogin = useSelector(getIsAuth)
  const isLoading = useSelector(getMainPageIsLoading)
  const dispatch = useDispatch()
  const history = useHistory()
  const { pathname } = useLocation()
    const { keycloak } = useKeycloak()

    if (keycloak.authenticated) {
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`
        apiToken.defaults.headers.common['Authorization'] = `Bearer ${keycloak.token}`
        saveToLocalStorage('token', keycloak.token)
        dispatch(isAuth(true))
    }

  // Get user data
  useEffect(() => {
    isLogin && dispatch(getUser())
      .catch(err => {
        const { status } = err.response
        let message, email

        if (err.response.data.messages && err.response.data.messages.length) {
          message = err.response.data.messages[0].message
          email = err.response.data.messages[0].context.email
        }

        if (status === 403 && message === 'New email is not confirmed') {

          history.push({
            pathname: '/verification-email',
            state: {
              from: pathname,
              userForm: {
                email
              }
            }
          })
        }

        dispatch(mainPageIsLoading(false))
      })
  }, [])

  // Get user tokens
  useEffect(() => {

    isLogin && dispatch(getTokens())
  }, [])

  return isLoading ? 
  (<div className="main-page__spinner">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 38 }} spin />} />
   </div>
  ) : (
    <div className="main-page">
      <Sidebar />
      <main className="main">
        <Switch>
          {/*{ !isLogin && <Redirect to="/login" /> }*/}
          { pathname === '/' && <Redirect to="/welcome" /> }
          
          {
            routes.map(route => (
              <Route 
                key={ route.path }
                path={ route.path }
                exact={ route.exact }
                component={ route.component }
              />
            ))
          }
        </Switch>
      </main>
      <MobileMenuButton />
    </div>
  )
}

export default Home