import React from 'react'
import { devices } from '../constants'

const CompatibleDevices = () => {

  return (
    <section className="compatible-devices">
      <h2 className="caption-primary">Compatible Devices</h2>
      <div className='flex-wrap'>
        {/* Samsung */}
        <div>
          <h3 className="caption-secondary">Android</h3>
          <div className="device">Samsung</div>
          <ul>
            {
              devices[0].android.samsung.map((item, index) => <li key={ index }>{ item }</li>)
            }
          </ul>
        </div>

        {/* Google */}
        <div>
          <h3 className="caption-secondary">Android</h3>
          <div className="device">Google</div>
          <ul>
            {
              devices[0].android.google.map((item, index) => <li key={ index }>{ item }</li>)
            }
          </ul>
        </div>

        {/* iOS */}
        <div>
          <h3 className="caption-secondary">iOS</h3>
          <ul>
            {
              devices[1].ios.map((item, index) => <li key={ index }>{ item }</li>)
            }
          </ul>
        </div>

        <p>
          *Series includes all models of this product line. For example, Galaxy S10
          series includes S10, S10e, S10+, and S10 5G
        </p>
      </div>
    </section>
  )
}

export default CompatibleDevices