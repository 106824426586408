import React from 'react'

const TestApp = () => (
  <p>
    After you deployed the build onto your device, go outside near the 
    location where you added your <code className="code-text">WorldAnchor
    </code> object and start the app. Once the device camera feed starts, 
    point your camera outward and hit the scan button and follow the arrows
    on screen to capture images. After few seconds, you should see your 
    object exactly where you placed it on the map!
  </p>
)

export default TestApp