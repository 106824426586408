import axios from 'axios';

const BASE_API_URL = 'https://api.sturfee.com/api/0.2.0';

const apiCoverage = axios.create({
  baseURL: BASE_API_URL,
  mode: 'no-cors',
  headers: {
    post: {
      'Content-Type': 'application/json'
    } 
  }
});

// Setting Authorization token 
const tokenStr = localStorage.getItem('token')
const token = JSON.parse(tokenStr)

if (token) {
  apiCoverage.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

apiCoverage.interceptors.request.use((config) => {
  config.params = config.params || {};
  return config;
});

export default apiCoverage;