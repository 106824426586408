import { apiClient, apiToken, apiCoverage } from './axios-instance'
import * as endpoints from './endpoints'

/* Client API */
export const login = (data, config) => apiClient.post(endpoints.login, data, config)
export const registration = (data, config) => apiClient.post(endpoints.user, data, config)
export const emailConfirm = (md5, config) => apiClient(endpoints.emailConfirm + '/' + md5, config)
export const restorePassword = (md5, data, config) => apiClient.post(endpoints.passwordRestoration + '/' + md5, data, config)
export const getUser = config => apiClient(endpoints.user, config)
export const changeUser = (uuid, data, config) => apiClient.patch(endpoints.user + '/' + uuid, data, config)
export const passwordRestoration = (data, config) => apiClient.post(endpoints.passwordRestoration, data, config)
export const resendVerification = (data, config) => apiClient.post(endpoints.resendEmailConfirm, data, config)
export const reenterEmail = (data, config) => apiClient.post(endpoints.reenterEmail, data, config)

/* Token API */
export const getTokens = config => apiToken(endpoints.tokens, config)
export const changeTokenStatus = (data, config) => apiToken.patch(endpoints.activate, data, config)
export const createToken = (data, config) => apiToken.post(endpoints.register, data, config)
export const revokeToken = (data, config) => apiToken.patch(endpoints.revoke, data, config)
export const updateToken = (data, config) => apiToken.patch(endpoints.update, data, config)
export const cleanAccountData = config => apiToken.delete(endpoints.clean, config)

/* Coverage API */
export const getAlignmentAvailable = (data, config) => apiCoverage.get(endpoints.alignmentAvailable, { params: data }, config);
