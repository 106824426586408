import React from 'react'
import { useHistory } from 'react-router-dom'

import TokenTable from './TokenTable'
import { Button } from 'antd'

const GenerateToken = () => {
  const history = useHistory()

  const handleClick = () => {
    history.push('/sdk/create-token')
  }

  return (
    <article>
      <h2 className="caption-primary">Generate Token</h2>
      <p>
        Be sure to generate and copy an access token as you will need it 
        during set up.
      </p>
      <p>
        <Button
          onClick={ handleClick }
          type="primary" 
          shape="round" 
          className="button-primary"
        >
          Create a Token
        </Button>
      </p>
      <TokenTable />
    </article>
  )
}

export default GenerateToken