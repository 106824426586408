export const menuItems = [
  {
    title: 'Welcome',
    path: '/welcome'
  },
  {
    title: 'Quick Start',
    path: '/quick-start'
  },
  {
    title: 'Coverage Map',
    path: '/coverage-map'
  },
  {
    title: 'SDK & Access Token',
    path: '/sdk'
  },
  {
    title: 'Documentation',
    path: '/documentation'
  },
  {
    title: 'Sample Apps',
    path: '/sample-apps'
  },
  {
    title: 'Compatible Devices',
    path: '/compatible-devices'
  },
  {
    title: 'Analytics',
    path: '/analytics'
  },
  {
    title: 'Settings',
    path: '/settings',
    submenu: [
      {
        title: 'Profile',
        path: '/profile',
      },
      {
        title: 'Billing',
        path: '/billing'
      },
    ]
  },
  {
    title: 'Logout',
    path: '/logout'
  }
]