import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { 
  toEdit, 
  setEditingTokenKey
} from '../'

import { Button } from 'antd'

const EditActions = ({ handleSave, tokenId }) => {
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const handleClick = () => {
    setIsLoading(true)
    handleSave(tokenId)
  }

  const handleCancel = () => {
    dispatch(setEditingTokenKey(''))
    dispatch(toEdit(false))
  }

  return (
    <div>
      <Button
        onClick={ handleClick }
        loading={ isLoading }
        type="link"
        style={{
          marginRight: '20px'
        }}
        danger
      >
        Save
      </Button>
      <Button
        onClick={ handleCancel }
        type="link"
      >
        Cancel
      </Button>
    </div>
  )
}

export default EditActions