import React, { createRef } from 'react'

import { Button, Tooltip, notification } from 'antd'
import { CopyFilled } from '@ant-design/icons'

const TokenRaw = ({ tokenRaw }) => {
  const tokenVal = createRef()

  const handleClick = () => {
    const tokenEl = tokenVal.current
 
    const range = document.createRange()
    range.selectNodeContents(tokenEl)
    const selection = window.getSelection()

    selection.removeAllRanges()
    selection.addRange(range)

    document.execCommand("copy")
    selection.removeAllRanges()

    notification.success({
      message: 'Token copied!',
      placement: 'bottomLeft',
      duration: 3
    })
  }

  return (
    <div className="token">
      <div ref={ tokenVal } className="token__row">
        { tokenRaw }
      </div>
      <div>
        <Tooltip placement="top" title="Copy token">
          <Button
            onClick={ handleClick } 
            icon={ <CopyFilled /> }
            className="copy-icon"
            type="link"
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default TokenRaw