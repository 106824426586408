import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { menuIsOpen } from '../actions'
import { getIsMobileMenuOpen } from '../../../selectors'

const MobileMenuButton = () => {
  const dispatch = useDispatch()
  const isMobileMenuOpen = useSelector(getIsMobileMenuOpen)

  useEffect(() => {
    isMobileMenuOpen ?
      document.querySelector('body').classList.add('hidden') :
      document.querySelector('body').classList.remove('hidden')
  }, [isMobileMenuOpen])

  const handleClick = () => {
    isMobileMenuOpen ?
      dispatch(menuIsOpen(false)) :
      dispatch(menuIsOpen(true))
  }

  return (
    <button
      onClick={ handleClick }  
      className={ 
        isMobileMenuOpen ? 
        'mobile-menu-btn mobile-menu-btn_toggled' :
        'mobile-menu-btn'
      }
    >
      <span className='mobile-menu-btn__inner'></span> 
    </button>
  )
}

export default MobileMenuButton