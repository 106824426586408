export const devices = [
  {
    android: {
      samsung: [
        'Galaxy S8 series*',
        'Galaxy S9 series',
        'Galaxy S10 series',
        'Galaxy S20 series',
        'Galaxy Note 8 series',
        'Galaxy Note 9 series',
        'Galaxy Note 10 series'
      ],
      google: [
        'Pixel 2 series',
        'Pixel 3 series',
        'Pixel 4 series'
      ]
    }
  },
  {
    ios: [
      'iPhone 7 series',
      'iPhone 8 series',
      'iPhone X series',
      'iPhone 11 series'
    ]
  }
]