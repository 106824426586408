import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { 
  getCoverage,
  coverageCheckLoading,
  setCheckedCoverage
} from '..';

import { 
  getCoverageCheckIsLoading,
  getCoverageCheck,
  getHasCheckedCoverage  
} from '../../../selectors'

import { Input, Form, Button } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';


const TokenCoverageCheck = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector(getCoverageCheckIsLoading);
  const coverageResult = useSelector(getCoverageCheck);
  const hasSubmitted = useSelector(getHasCheckedCoverage);

  const handleCheckCoverage = ({ token, latLng }) => {
    let latitude = 0;
    let longitude = 0;
    try {
      let latTxt = latLng.trim().split(',')[0].trim();
      let lngTxt = latLng.trim().split(',')[1].trim();
      
      if (isNaN(latTxt)) { throw 'NaN'; }
      if (isNaN(lngTxt)) { throw 'NaN'; }

      latitude = parseFloat( latTxt );
      longitude = parseFloat( lngTxt );

    } catch (err) {
      console.log(err);
      alert('Please check your Latitude and Longitude values. Only numbers are accepted.');
      return;
    }    

    // validate lat/lng
    if (!(-80.0 <= latitude && latitude <= 84.0)) {
      alert('The Latitude out of range (must be between 80 deg S and 84 deg N)');
      return;
    }
    if (!(-180.0 <= longitude && longitude <= 180.0)) {
      alert('The Longitude out of range (must be between 180 deg W and 180 deg E)');
      return;
    }

    dispatch(coverageCheckLoading(true));
    dispatch(setCheckedCoverage(true));
    
    dispatch(getCoverage(token, latitude, longitude))
      .then(res => {
      });
  };

  const renderMessage = (hasSubmitted) => {
    if (!hasSubmitted) {
      return <div></div>
    }
    if (isLoading) {
      return <p>Loading...</p>
    } else if (coverageResult) {
      return <p><CheckCircleTwoTone twoToneColor="#52c41a" />&nbsp;&nbsp;<strong>VPS Available at Above Location</strong></p>
    } else {
      return <p><CloseCircleTwoTone twoToneColor="#ff141a" />&nbsp;&nbsp;<strong>NOT Available!</strong></p>
    }
  }

  return (
    <article>
      <h2 className="caption-primary">Check Coverage</h2>
      <p>
        Enter access token and location to check VPS coverage
      </p>
        <Form
        name="checkCoverageForm"
        onFinish={handleCheckCoverage}
        className="ant-table-wrapper container-only"
      >
        <Form.Item
          label="Token"
          name="token"
          rules={[{ required: true, message: 'Please input your Token' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Latitude, Longitude"
          name="latLng"
          rules={[{ required: true, message: 'Please input your Latitude, Longitude' }]} 
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary"
            shape="round" 
            className="button-primary" 
            htmlType="submit">
            Submit
          </Button>
        </Form.Item>

        {renderMessage(hasSubmitted)}

      </Form>
    </article>
    
  )
}

export default TokenCoverageCheck

