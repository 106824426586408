import axios from 'axios'
import store from '../../storeConfig'
// import { isAuth } from '../../pages/login/actions'
import { deleteFromLocalStorage } from '../../helpers'
import { BASE_API_URL } from '../urlConfig'

// const BASE_API_URL = 'https://demo-sturfeeapi.zimalab.com/api/v1'

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    post: {
      'Content-Type': 'application/json'
    } 
  }
})

// Setting Authorization token 
const tokenStr = localStorage.getItem('token')
const token = JSON.parse(tokenStr)

if (token) {
  apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

// If 401/403 redirect to '/login'
const onResponseSuccess = response => response

const onResponseFail = error => {
  let message
  const { status } = error.response

  if (error.response.data.messages && error.response.data.messages.length) {
    message = error.response.data.messages[0].message
  }

  if (status === 401 || status === 403 && message !== 'New email is not confirmed') {
    deleteFromLocalStorage('token')
    // store.dispatch(isAuth(false))
  }

  return Promise.reject(error)
}

apiClient.interceptors.response.use(onResponseSuccess, onResponseFail)

export default apiClient