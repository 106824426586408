import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'

import { menuItems } from '../constants'
import { getIsMobileMenuOpen } from '../../../selectors'
import { deleteFromLocalStorage } from '../../../helpers'

// Actions
import { menuIsOpen } from '../../mobile-menu-button'
// import { isAuth } from '../../../pages/login'

import { Menu } from 'antd'
import { useKeycloak } from '@react-keycloak/web'

const { SubMenu } = Menu

const Sidebar = () => {
  const { keycloak } = useKeycloak()

  const [selectedKeys, setSelectedKeys] = useState([])
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const isMobileMenuOpen = useSelector(getIsMobileMenuOpen)

  useEffect(() => {
    updateActiveMenuItem()
  }, [location])

  useEffect(() => {
    window.innerWidth <= 768 && dispatch(menuIsOpen(false))
  }, [location])

  const updateActiveMenuItem = () => {
    const path = location.pathname
    
    const arrPath = path.split('/')
    arrPath.shift()

    const result = arrPath.reduce((prevValue, item) => {
      prevValue.push(`/${item}`)
      return prevValue
    }, [])

    setSelectedKeys(result)
  } 

  const handleClick = ({ keyPath }) => {
    const path = getPath(keyPath)

    if (path === '/logout') {
      deleteFromLocalStorage('token')
      keycloak.logout()
      // dispatch(isAuth(false))
    } else {
      history.push(path)
    }
  }

  const getPath = (pathArr) => {
    let path = ''

    for (let i = pathArr.length-1; i >= 0; i--) {
      path += `${pathArr[i]}`
    }

    return path
  }

  const createMenuItem = (item) => {
    return item.submenu ? (
      <SubMenu 
          key={ item.path }
          title={ item.title }
        >
          {
            item.submenu.map(item => {
              return createMenuItem(item)
            })
          }
        </SubMenu>
    ) : (
      <Menu.Item key={ item.path }>
        { item.title }
      </Menu.Item>
    )
  }

  return (
    <aside className={ isMobileMenuOpen ? 'aside isOpen' : 'aside' }>
      <nav>
        <Menu
          selectedKeys={ selectedKeys }
          onClick={ handleClick }
          className="navigation"
          theme="light"
          mode="inline"
        >
          {
            menuItems.map(item => createMenuItem(item))
          }
        </Menu>
      </nav>
    </aside>
  )
}

export default Sidebar