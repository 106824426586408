import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'

const SdkDownload = () => {

  return (
    <article>
      <h2 className="caption-primary">SDK Download</h2>
      <p>
        Please the <Link to="/quick-start" className="link-primary">Quick Start
        </Link> guide for installation instructions.
      </p>
      <p>
        <b>Current SDK version: 3.0.5</b>
      </p>
      {/* <Button
        type="primary" 
        shape="round" 
        icon={ <DownloadOutlined /> } 
        className="button-primary"
      >
          <a href="https://sturfee-public-assets.s3.amazonaws.com/downloads/sdk/sturfee-vps-sdk-2.3.0.zip">Download Unity SDK</a>
      </Button> */}
    </article>
  )
}

export default SdkDownload