import types from './actionTypes'

const initialState = {
  username: '',
  fullname: '',
  email: '',
  organization: '',
  uuid: ''
}

const reducer = ( state = initialState, action ) => {
  switch (action.type) {
    case types.USER_EMAIL: 
      return {
        ...state,
        email: action.payload
      }
    case types.USER_FULLNAME:
      return {
        ...state,
        fullname: action.payload
      }
    case types.USER_NAME:
      return {
        ...state,
        username: action.payload
      }
    case types.USER_ORGANIZATION:
      return {
        ...state,
        organization: action.payload
      }
    case types.USER_UUID:
      return {
        ...state,
        uuid: action.payload
      }
    default:
      return state
  }
}

export default reducer