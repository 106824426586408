import React from 'react'

const Start = () => {

  return (
    <article>
      <h2 className="caption-primary">Quick Start</h2>
    </article>
  )
}

export default Start