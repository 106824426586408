import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'


import api from '../../../api'
import {
  getUserFullname,
  getUsername,
  getUserEmail,
  getUserOrganization,
  getUserUuid
} from '../../../selectors'
import {
  userFullname,
  userEmail,
  userOrganization
} from '../actions'

import { Form, Input, Button, message } from 'antd'
import { 
  UserOutlined, 
  BankOutlined,
  MailOutlined 
} from '@ant-design/icons'

function parseJwt(token) {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

const Profile = () => {
  const { keycloak, initialized } = useKeycloak()

  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [form] = Form.useForm()

  let data = parseJwt(localStorage.getItem ('token'))

  const user_name = data.preferred_username
  const uuid = data.sub
  // It needs for check new values with initial for request
  const user_full_name = data.name
  const user_mail = data.email
  const user_organization = useSelector(getUserOrganization)


  useEffect(() => {
    form.setFieldsValue({
      username: user_name,
      fullname: user_full_name,
      email: user_mail,
      organization: user_organization
    })
  }, [user_full_name, 
    user_name,
    user_mail, 
    user_organization
  ])

  const onFinish = values => {
    let {
      fullname,
      organization,
      email
    } = values

    fullname = fullname.trim()
    organization = organization.trim()
    email = email.trim()

    const data = {}

    if (fullname !== user_full_name) {
      data.full_name = fullname
    } 
    if (organization !== user_organization) {
      data.organization = organization
    } 
    if (email !== user_mail) {
      data.email = email
    }
    
    setIsLoading(true)

    api.changeUser(uuid, data)
      .then(() => {
        if (data.email) {

          history.push({
            pathname: '/verification-email',
            state: {
              from: location.pathname,
              userForm: {
                email
              }
            }
          })
        } else {
          message.success('Profile has been changed successfully!', 5)

          if (fullname !== user_full_name) {
            dispatch(userFullname(fullname))
          } 
          if (organization !== user_organization) {
            dispatch(userOrganization(organization))
          } 
          if (email !== user_mail) {
            dispatch(userEmail(email))
          }

          setIsLoading(false)
        }
      })
      .catch(err => {
        const status = err.response.status
        const message = err.response.data.messages[0].message

        if (status === 400 && message === 'This email already used') {
          form.setFields([{
            name: 'email',
            errors: ['This email already used by another account']
          }]) 
        } else if (status === 400 && message === 'Validation error') {
          form.setFields([{
            name: 'email',
            errors: ['Not a valid email']
          }]) 
        }

        setIsLoading(false)
      })
  }

  return (
    <section className="profile">
      <h2 className="caption-primary">Settings</h2>
      <h3 className="caption-secondary">Profile</h3>
      <Form
        form={ form }
        onFinish={ onFinish }
        layout="vertical"
        className="app-form"
      >
        <Form.Item
          label="Username"
          name='username'
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Full Name"
          name="fullname"
          rules={[
            { required: true, message: 'Please input your full name!' },
            { min: 3, message: 'Full name must be at least 3 characters!' }
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Organization"
          name="organization"
          rules={[
            { required: true, message: 'Please input your organization!' },
            { min: 3, message: 'Organization must be at least 2 characters!' }
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input your email!' }
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item shouldUpdate={ true }>
          {
            // () => (
            //   <Button
            //     htmlType="submit"
            //     loading={ isLoading }
            //     disabled={
            //       (user_full_name === form.getFieldValue('fullname') &&
            //       user_mail === form.getFieldValue('email') &&
            //       user_organization === form.getFieldValue('organization')) ||
            //       form.getFieldsError().filter(({ errors }) => errors.length).length
            //     }
            //     type="primary"
            //     shape="round"
            //     className="button-primary"
            //   >
            //     Save
            //   </Button>
            // )
          }
        </Form.Item>
      </Form>
    </section>
  )
}

export default Profile